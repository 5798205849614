import React, { useEffect } from "react";
import Header from "../../../components/home/Header";
import Footer from "../../../components/home/Footer";
import SuccessTick from "../../../images/successtick.svg";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import "../../../i18n";

const ContactSuccess = ({ location }) => {
  const { t } = useTranslation();

  if (location.state === null) {
    navigate("/contact");
  }

  return (
    !!location.state && (
      <>
        <Header />
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <SuccessTick />
          <h1 className="mt-7 lg:w-5/12 mx-10 text-center">
            {t("contact.success.headingOne", {
              name: location.state.name,
            })}
            <br className="lg:hidden" />
            {t("contact.success.headingTwo")}
          </h1>
          <div className="body-text-1 mt-6 lg:w-4/12 mx-10 text-center">
            {t("contact.success.subHeading")}
          </div>
        </div>
        <Footer />
      </>
    )
  );
};

export default ContactSuccess;
