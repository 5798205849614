import React, { useState } from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import twitterImg from "./twitter.svg";
// import instaImg from "../../../assets/Vectorimage4.png";
import facebookImg from "./fb.svg";
import linkedInImg from "./linkedin.svg";
// import githubImg from "../../../assets/Vectorimage1.png";
import HatioImg from "../../../images/logo-blue.svg";
import {
  DOCUMENTATION_HOSTNAME,
  PAYOUTS_HOSTNAME,
} from "../../../utils/constants";
import Modal from "../../common/Modal";
import ContactUs from "../ContactUs";
import "./styles.css";

const Footer: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();

  /**
   * Scroll to the pricing section
   */
  const handleScrollToSection = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    section: string
  ) => {
    e.preventDefault();
    // eslint-disable-next-line no-unused-expressions
    if (document.getElementById(section)) {
      document.getElementById(section)?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.location.replace(`/#${section}`);
    }
  };

  const [div, setDiv] = useState(-1);
  const [openDiv, setOpenDiv] = useState(true);

  const setActiveDiv = (e: React.MouseEvent, num: number): void => {
    e.preventDefault();
    setOpenDiv(!openDiv);
    setDiv(num === div ? -1 : num);
  };

  return (
    <footer className="flex flex-col bg-background-vanilla px-5">
      <div className="flex flex-col md:flex-row justify-between items-center gap-6 px-0 pt-6 pb-6 footer-img bg-top md:bg-left-top">
        {/* <div> */}
        <div className="text-center md:text-left mb-6 md:m-0 order-last md:order-none">
          <HatioImg className="h-12 mx-auto md:mx-0 mb-4" />
          <p className="caption-12 w-full text-center md:text-left">
            {t("home.footer.copyrightText")}
          </p>
        </div>
        {/* </div> */}

        <div className="flex gap-4 items-end">
          <div className={div !== 1 || openDiv ? "contents" : "hidden"}>
            <a
              className="body-text-2 neutral-black-coral "
              href="/about"
              rel="noopener noreferrer"
            >
              {t("home.footer.col3.links.about")}
            </a>
            <a
              className="body-text-2 neutral-black-coral "
              href="/culture"
              rel="noopener noreferrer"
            >
              {t("home.footer.col3.links.culture")}
            </a>
            <a
              className="body-text-2 neutral-black-coral "
              href="/careers"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("home.footer.col3.links.careers")}
            </a>
            <a className="body-text-2 neutral-black-coral " href="/contact">
              {t("home.footer.col3.links.contactUs")}
            </a>
          </div>
        </div>
        <div className="caption-12 hidden md:block text-center">
          {t("home.footer.madeWith.text")} &nbsp;
          <i className="fas fa-heart" /> &nbsp;{" "}
          {t("home.footer.madeWith.where")}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
